import type { AuthLogin, SsoProvider } from '~/types/Auth'
import type { AuthFetch } from '~/types/AuthFetch'

type Payload = {
  email: string
  password: string
}

/**
 * Provider to log into the Auth service using email and password.
 *
 * @param authFetch - auth fetch
 * @returns The api call function.
 */
export function provideAuthApiLogin(authFetch: AuthFetch) {
  return function (payload: Payload) {
    return authFetch.$post<AuthLogin>('/api/login/', payload)
  }
}

/**
 * Provider to log into the Auth service using an SSO code.
 *
 * @param authFetch - auth fetch.
 * @returns The api call function.
 */
export function provideAuthApiSsoLogin(authFetch: AuthFetch) {
  return function (provider: SsoProvider, code: string) {
    return authFetch.$post<AuthLogin>(`/api/sso/${provider}/login/`, {
      code,
    })
  }
}
